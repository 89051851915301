<template>
    <div class="home-page bg-bej-500">
        <a href="https://wa.me/0742116999" target="_blank" class="fixed bottom-10 right-12 z-50">
            <img src="../assets/images/whatsappButon.svg" alt="WhatsApp" class="whatsapp-button"/>
        </a>

        <div v-if="isCookieConsentVisible" id="cookieConsent" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4 h-screen w-screen">
            <div class="bg-maro-700 p-6 rounded-lg max-w-lg w-full mx-auto h-fit-content min-h-11/12 relative">
                <button @click="hideCookieConsent" class="absolute top-0 right-0 mt-4 mr-4 text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <div class="flex flex-col mt-16 px-8 gap-8 w-11/12">
                    <h3 class=" font-grafolita text-white text-3xl">Cookies?</h3>
                    <p class=" font-sans text-white text-sm">Prelucrăm datele dvs. pentru a vă livra conținut sau reclame și pentru a măsura livrarea acestor conținuturi sau reclame pentru a extrage informații despre site-ul nostru. Împărtășim aceste informații cu partenerii noștri pe baza consimțământului. Vă puteți exercita dreptul la consimțământ, pe baza unui scop specific de mai jos sau la nivel de partener în link-ul de sub fiecare scop. Aceste alegeri vor fi semnalate furnizorilor noștri care participă la Cadrul de transparență și consimțământ.</p>
                    <button @click="acceptCookies" class="text-white flex items-center gap-2 bg-maro-500 w-fit px-8 py-2 border-2 border-white rounded-md">Acceptați<img src="../assets/images/cookie.svg" alt="Cookies"></button>

                    <div class=" bg-maro-500 w-full grid grid-cols-4 auto-rows-auto rounded-md box-border border-white border-2 h gap-y-4 p-4">
                        <h4 class="text-white font-grafolita text-lg col-span-2 col-start-1">Gestionați preferințele de consimțământ</h4>

                        <p class="text-white font-sans text-base col-span-2 col-start-1 row-start-2">Cookie de targetare</p>
                        <label class="inline-flex items-center cursor-pointer col-span-1 row-start-2 col-start-4">
                            <input type="checkbox" value="" class="sr-only peer">
                            <div class="relative w-11 h-6 bg-maro-600 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-white peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] peer-checked:after:bg-maro-600 after:bg-gray-700 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-bej-500"></div>
                            <!-- <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span> -->
                          </label>

                          <p class="text-white font-sans text-base col-span-2 col-start-1 row-start-3">Cookie de targetare</p>
                        <label class="inline-flex items-center cursor-pointer col-span-1 row-start-3 col-start-4">
                            <input type="checkbox" value="" class="sr-only peer">
                            <div class="relative w-11 h-6 bg-maro-600 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-white peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] peer-checked:after:bg-maro-600 after:bg-gray-700 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-bej-500"></div>
                            <!-- <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span> -->
                          </label>

                          <p class="text-white font-sans text-base col-span-2 col-start-1 row-start-4">Cookie de targetare</p>
                        <label class="inline-flex items-center cursor-pointer col-span-1 row-start-4 col-start-4">
                            <input type="checkbox" value="" class="sr-only peer">
                            <div class="relative w-11 h-6 bg-maro-600 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-white peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] peer-checked:after:bg-maro-600 after:bg-gray-700 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-bej-500"></div>
                            <!-- <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span> -->
                          </label>
                    </div>
                    
                </div>
            </div>
        </div>
          

        <div class="bg-maro-700 flex justify-between text-white items-center 2xl:h-20 h-16 w-full font-sans md:px-72 sticky top-0 md:z-20 px-8 z-40">
            <span class="h-full w-fit"><img src="../assets/images/Logo.png" alt="Logo" class="h-full w-auto"></span>
            <nav class="md:flex justify-center items-center h-full gap-14 hidden">
                <span class="text-white md:flex items-center justify-center gap-2 2xl:text-xl text-base font-sans hidden">
                   <img src="../assets/images/telefon.svg" alt="Telefon">
                   +40 742 116 999
                </span>
                <span class="text-white md:flex items-center justify-center gap-2 2xl:text-xl text-base font-sans hidden">
                    <img src="../assets/images/mail.svg" alt="Mail">
                    vilacrisalia@gmail.com
                </span>
            </nav>
            <div>
                <span class="text-white font-light text-xl ">🇷🇴/🇩🇪</span>
            </div>
        </div>
        <div class=" bg-maro-400 h-16 w-full font-sans px-8 md:hidden flex justify-between z-40 top-16 sticky">
            <div class="flex flex-col w-1/2 items-start justify-around">
                <span class="text-white md:flex items-center md:justify-center gap-2 2xl:text-xl md:text-base text-sm font-sans w-full flex h-1/3">
                   <img src="../assets/images/telefon.svg" alt="Telefon" class=" h-4/6">
                   +40 742 116 999
                </span>
                <span class="text-white md:flex items-center md:justify-center gap-2 2xl:text-xl md:text-base text-sm font-sans w-full flex h-1/3">
                   <img src="../assets/images/mail.svg" alt="Telefon" class="h-4/6">
                   vilacrisalia@gmail.com
                </span>
            </div>

            <span class=" self-center " @click="toggleMenu">
                <img v-show="!isMenuVisible" src="../assets/images/menu.png" alt="Menu" class="h-4">
                <img v-show="isMenuVisible" src="../assets/images/x-crisalia.png" alt="Close" class=" h-6 z-40">
            </span>
            
        </div>
        <div v-show="isMenuVisible" class="fixed inset-0 bg-black bg-opacity-50 z-30" @click.stop>
                <div class="menu-content bg-maro-400 h-full w-64 fixed inset-y-0 right-0 top-32 transform translate-x-0 transition duration-300 ease-in-out flex flex-col text-white text-xl pt-28">
                    <a href="#facilitati" class=" ml-8 py-4 border-b-2 border-b-white">Facilitati</a>
                    <a href="#despre" class=" ml-8 py-4 border-b-2 border-b-white">Despre noi</a>
                    <a href="#atractii" class=" ml-8 py-4 border-b-2 border-b-white">Atractii turistice</a>
                    <a href="#oferta" class=" ml-8 py-4 border-b-2 border-b-white">Preturi si pachete</a>
                    <a href="#reviews" class=" ml-8 py-4 border-b-2 border-b-white">Reviewuri</a>
                    <a href="#locatie" class=" ml-8 py-4 border-b-2 border-b-white">Contact</a>
                </div>
        </div>
        <div class="md:flex w-full justify-center items-center text-maro-500 z-10 font-sans 2xl:text-xl text-base py-8 font-light gap-16 hidden">
            <a href="/#facilitati">Facilități</a>
            <a href="/#atractii">Atracții turistice</a>
            <a href="/#galerie">Galerie</a>
            <a href="/#oferta">Prețuri și pachete</a>
            <a href="/#reviews">Reviewuri</a>
            <a href="/#locatie">Contact</a>
        </div>

        <div id="regulament" class="flex flex-col 2xl:mx-80 md:mx-40 mx-8 text-maro-500 mt-16 gap-14 pb-32">
            <h1 class="font-grafolita text-maro-500 text-center 2xl:text-7xl md:text-6xl text-5xl leading-loose">Regulament intern <br>Vila Crisalia</h1>
            <p class="text-maro-500 font-sans text-lg">În calitate de Proprietari, dorim să aveţi o şedere plăcută în vila noastră, asigurându-vă că ne vom strădui ca toate serviciile oferite să fie la nivelul cerinţelor dumneavoastră. Prezentul regulament se aplică tuturor turiştilor, care îl acceptă prin însuşi faptul cazării lor în vilă şi trebuie să i se conformeze fără limite sau rezerve. Vă rugăm să respectați următoarele reguli de ordine interioară: - Ziua turistică începe la ora 14.00 și se termină a doua zi la ora 11.00. - Parola internet este crisalia - Accesul în interiorul vilei se face cu o cartelă pe care o veți regăsi la cheile camerei. Vă rugăm să închide-ti poarta tot timpul când plecați. - Persoanele care vin în vizită trebuiesc sa fie anunțate . - Fumatul este permis doar în exteriorul pensiunii. 
                <br><br><span class="font-bold">SAUNA</span><br><br>
                 Încălzirea acesteia se realizează în aproximativ 1 oră jumătate prin apăsarea celor două întrerupătoare. După folosirea saunei vă rugăm să opriți temperatura și lumina de la cele două întrerupătoare. Nu setați temperatura mai mult de 80 grade. Nu ne asumăm răspunderea dacă exagerați cu timpul de stat în saună cât și cu temperatura setată. Dacă utilizați sauna pentru prima dată vă recomandăm să o frecventați treptat începând cu maxim 2-3 min. la ședința. Nu recomandăm utilizarea saunei următoarelor persoane: gravide, cu boli cardio-vasculare, alergice, cu boli de piele, cu disfuncții vegetative, cu boli ale sistemului locomotor(reumatism si spondiloza), cu viroza respiratorie, cu boli ginecologice, cu dereglări hormonale în perioada de fertilitate feminina si menopauza, epiletice cu tumori maligne, cu stări febrile, cu ulcer gastroduodenal. Nu este recomandată sauna copiilor sub 18 ani. Părinții care nu respectă această recomandare își asumă întreaga responsabilitate. În afară de apa simpla sau în amestec cu esențe naturale nu turnați nimic altceva pe pietrele de la saună, riscați un incendiu sau generare de mirosuri neplăcute. Vă rugăm să folosiți apa în cantități moderate. 
                 <br><br><span class="font-bold">JACUZZI</span><br><br>
                  Acesta este pregătit la o temperatură de 38 grade. La momentul folosinței se dă capacul jos și se va apăsa doar pe butonul de jeturi.După folosirea pompelor de contracurent și a jacuzzi-ului vă rugăm să le închideți imediat tot de la butonul de unde au fost pornite și să puneți capacul imediat după folosință; 
                  <br><br><span class="font-bold">CIUBĂR</span><br><br>
                   Acesta va fi încălzit doar la cerere. ·După folosirea ciubarului vă rugăm să puneți capacul pentru a se păstra căldura și pentru a doua zi; 
                   <br><br><span class="font-bold">ȘEMINEU</span><br><br>
                    Dacă se dorește folosirea acestuia când se deschide ușa trebuie să fie trase cele doua tije în afară ca să nu intre fum în încăpere . La frecventarea saunei, jacuzzi, ciubarului in mod obligatoriu trebuie sa respectati urmatoarele reguli: - Înainte de a frecventa ciubarul, jacuzzi sau sauna este obligatoriu sa faceti dus. - Este strict interzis sa intrați în ciubar, jacuzzi cu pielea necurățată de produse cosmetice (creme, farduri, etc.) cât și introducerea în apa de spumanti, saruri, uleiuri parfumate sau alte substanțe. - Nu scuipati, nu suflati nasul și nu urinati in apa din ciubar sau jacuzzi; - Recomandăm folosirea castilor în mod deosebit persoanelor cu parul lung; - Este strict interzis accesul copiilor în ciubar, jacuzzi,neinsotiti și nesupravegheati; - Sunt interzise sariturile în ciubar, jacuzzi. ❗️ În caz contrar nu ne asumăm răspunderea asupra unui eventual neplăcut accident.❗️ În situația în care din diverse motive apa din ciubar și/sau jacuzzi nu este păstrată curată pe perioada șederii dv, aceasta se poate schimba doar contra cost. La frecventarea bucătărie, sălii de mese, a gratarului , în mod obligatoriu trebuie sa respectati urmatoarele reguli: - Bucătăria este disponibilă tuturor clienților dar ea este destinată preparării mâncărurilor simple - Vesela și tacâmurile folosite trebuie sa fie curățate înainte de plecare. - Pentru orice defecțiune apărută vă rugăm să ne anunțați imediat - Orice distrugere realizată din vina dvs trebuie plătită până la plecare. - Este strict interzis fumatul în interiorul pensiunii - După fiecare folosire a grătarului focul se va stinge cu apa.</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'RegulamentVila',
};
</script>

<style scoped>
/* Add styling for the Regulament component here */
.regulament-container {
  padding: 2rem;
}
</style>
