<template>
    <div class="home-page">
        <a href="https://wa.me/+40742116999" target="_blank" class="fixed bottom-10 right-12 z-50">
            <img src="../assets/images/whatsappButon.svg" alt="WhatsApp" class="whatsapp-button"/>
        </a>

        <div v-if="isCookieConsentVisible" id="cookieConsent" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4 h-screen w-screen">
            <div class="bg-maro-700 p-0 rounded-lg max-w-lg w-full mx-auto h-fit-content max-h-screen md:max-h-screen relative overflow-x-scroll" id="cookieBg">
                <button @click="hideCookieConsent" class="absolute top-0 right-0 mt-4 mr-4 text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <div class="flex flex-col mt-16 px-8 gap-8 md:w-11/12 w-full">
                    <h3 class=" font-grafolita text-white text-3xl">Cookies?</h3>
                    <p class=" font-sans text-white text-sm">{{ $t('cookies') }}</p>
                    <button @click="acceptCookies" class="text-white flex items-center gap-2 bg-maro-500 w-fit px-8 py-2 border-2 border-white rounded-md">{{ $t('accept') }}<img src="../assets/images/cookie.svg" alt="Cookies"></button>

                    <div class=" bg-maro-500 w-full grid grid-cols-4 auto-rows-auto rounded-md box-border border-white border-2 h gap-y-4 md:gap-y-2 p-4">
                        <h4 class="text-white font-grafolita text-2xl col-span-2 col-start-1">{{ $t('consent') }}</h4>

                        <p class="text-white font-sans text-base md:col-span-2 col-span-3 col-start-1 row-start-2">{{ $t('target') }}</p>
                        <label class="inline-flex items-center cursor-pointer col-span-1 row-start-2 col-start-4">
                            <input type="checkbox" value="" class="sr-only peer">
                            <div class="relative w-11 h-6 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-white bg-white peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] peer-checked:after:bg-maro-600 after:bg-gray-700 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-bej-500"></div>
                            <!-- <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span> -->
                          </label>

                          <p class="text-white font-sans text-base md:col-span-2 col-span-3 col-start-1 row-start-3">{{ $t('functionality') }}</p>
                        <label class="inline-flex items-center cursor-pointer col-span-1 row-start-3 col-start-4">
                            <input type="checkbox" value="" class="sr-only peer" checked>
                            <div class="relative w-11 h-6 bg-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-white peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] peer-checked:after:bg-maro-600 after:bg-gray-700 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-bej-500"></div>
                            <!-- <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span> -->
                          </label>

                          <p class="text-white font-sans text-base md:col-span-2 col-span-3 col-start-1 row-start-4">{{ $t('performance') }}</p>
                        <label class="inline-flex items-center cursor-pointer col-span-1 row-start-4 col-start-4">
                            <input type="checkbox" value="" class="sr-only peer">
                            <div class="relative w-11 h-6 bg-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-white peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] peer-checked:after:bg-maro-600 after:bg-gray-700 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-bej-500"></div>
                            <!-- <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span> -->
                          </label>
                    </div>
                    
                </div>
                <div class="flex sticky bottom-0 right-0 bg-maro-500 items-center justify-center h-28 mt-4 border-t-2 border-t-white">
                    <button @click="acceptCookies" class="text-white flex items-center gap-2 bg-maro-500 w-fit px-8 py-2 border-2 border-white rounded-md">{{ $t('accept2') }}<img src="../assets/images/cookie.svg" alt="Cookies"></button>
                </div>
            </div>
        </div>
          

        <div class="bg-maro-700 flex justify-between text-white items-center 2xl:h-20 md:h-16 h-20 w-full font-sans md:px-72 sticky top-0 md:z-20 px-8 z-40">
            <span class="h-full w-fit"><img src="../assets/images/Logo.png" alt="Logo" class="h-full w-auto"></span>
            <nav class="md:flex justify-center items-center h-full gap-14 hidden">
                <span class="text-white md:flex items-center justify-center gap-2 2xl:text-xl text-base font-sans hidden">
                   <img src="../assets/images/telefon.svg" alt="Telefon">
                   +40 742 116 999
                </span>
                <span class="text-white md:flex items-center justify-center gap-2 2xl:text-xl text-base font-sans hidden">
                    <img src="../assets/images/mail.svg" alt="Mail">
                    vilacrisalia@gmail.com
                </span>
            </nav>
            <div>
                <span class="text-white font-light md:text-xl text-xl">
                    <button @click="changeLanguage('ro')">🇷🇴</button>/<button @click="changeLanguage('de')">🇩🇪</button>/<button @click="changeLanguage('en')">🇬🇧</button>
                </span>
            </div>
        </div>
        <div class=" bg-maro-400 h-20 w-full font-sans px-8 md:hidden flex justify-between z-40 top-20 sticky">
            <div class="flex flex-col w-1/2 items-start justify-around">
                <span class="text-white md:flex items-center md:justify-center gap-2 2xl:text-xl md:text-base text-sm font-sans w-full flex h-1/3">
                   <img src="../assets/images/telefon.svg" alt="Telefon" class=" h-4/6">
                   +40 742 116 999
                </span>
                <span class="text-white md:flex items-center md:justify-center gap-2 2xl:text-xl md:text-base text-sm font-sans w-full flex h-1/3">
                   <img src="../assets/images/mail.svg" alt="Telefon" class="h-4/6">
                   vilacrisalia@gmail.com
                </span>
            </div>

            <span class=" self-center " @click="toggleMenu">
                <img v-show="!isMenuVisible" src="../assets/images/menu.png" alt="Menu" class="h-4">
                <img v-show="isMenuVisible" src="../assets/images/x-crisalia.png" alt="Close" class=" h-6 z-40">
            </span>
            
        </div>
        <div v-show="isMenuVisible" class="fixed inset-0 bg-black bg-opacity-50 z-30" @click.stop>
            <div class="menu-content bg-maro-400 h-full w-64 fixed inset-y-0 right-0 top-32 transform translate-x-0 transition duration-300 ease-in-out flex flex-col text-white text-xl pt-28">
                <a @click="toggleMenu" href="#facilitati" class="ml-8 py-4 border-b-2 border-b-white">{{ $t('facilitati') }}</a>
                <a @click="toggleMenu" href="#despre" class="ml-8 py-4 border-b-2 border-b-white">{{ $t('despre') }}</a>
                <a @click="toggleMenu" href="#atractii" class="ml-8 py-4 border-b-2 border-b-white">{{ $t('atractii') }}</a>
                <a @click="toggleMenu" href="#oferta"  class="ml-8 py-4 border-b-2 border-b-white">{{ $t('preturi') }}</a>
                <a @click="toggleMenu" href="#reviews" class="ml-8 py-4 border-b-2 border-b-white">{{ $t('reviews') }}</a>
                <a @click="toggleMenu" href="#locatie" class="ml-8 py-4 border-b-2 border-b-white">{{ $t('contact') }}</a>
            </div>
            </div>
        <div :style="{ backgroundImage: 'url(' + currentBackgroundImage + ')' }" id="hero" class="bg-hero-pattern bg-cover w-full md:h-80 m-0 p-0 relative bg-center flex flex-col items-center md:justify-start justify-center" :class="{ 'background-fade-in': videoEnded }">
            <video @ended="onVideoEnded" class="absolute inset-0 w-full h-full object-cover" :class="{ 'hidden': videoEnded }" autoplay muted>
            <source src="../assets/images/video.mp4" type="video/mp4">
            Your browser does not support the video tag.
            </video>
            <div class="absolute inset-0" style="background-color: rgba(33, 10, 10, 0.7);"></div>
            <div class="md:flex w-full justify-center items-center text-white z-10 font-sans 2xl:text-xl text-base py-8 font-light gap-16 hidden">
                <a href="#facilitati"  class="ml-8 py-4 cursor-pointer">{{ $t('facilitati') }}</a>
                <a href="#atractii" class="ml-8 py-4 cursor-pointer">{{ $t('atractii') }}</a>
                <a href="#galerie" class="ml-8 py-4 cursor-pointer">{{ $t('galerie') }}</a>
                <a href="#oferta" class="ml-8 py-4 cursor-pointer" >{{ $t('preturi') }}</a>
                <a href="#reviews" class="ml-8 py-4 cursor-pointer" >{{ $t('reviews') }}</a>
                <a href="#locatie" class="ml-8 py-4 cursor-pointer">{{ $t('contact') }}</a>
            </div>
            <div class="md:w-1/2 z-10 flex flex-col justify-center md:items-center lg:my-38 sm:mt-32 gap-8 md:mx-0 mx-8 h-full">
                <h1 class="text-white 2xl:text-8xl xl:text-7xl text-6xl md:text-center md:leading-relaxed font-grafolita font-thin ">{{ $t('titlu1') }}<br> {{ $t('titlu2') }}</h1>
                <p class="text-white 2xl:text-xl text-sm md:text-center font-sans">{{ $t('paragraf_hero') }}</p>
                <a href="#locatie" class="bg-maro-700 hover:bg-maro-700 text-white font-light 2xl:py-4 2xl:px-16 py-3 px-12 rounded my-6 2xl:text-xl text-base w-fit cursor-pointer">
                    {{ $t('contact_button') }}
                </a>
            </div>
        </div>
        <div ref="scrollContent" class="scroll-content">
        <div class="flex flex-col items-center w-full m-0 p-0 bg-bej-500 h-fit md:h-screen pb-24" id="facilitati" data-scroll-section>
            <h2 class="font-grafolita md:text-7xl text-5xl 2xl:mt-40 2xl:mb-10 mb-8 mt-32 text-maro-600">{{ $t('facilitati_vila') }}</h2>
            <div class="2xl:mx-52 mx-auto flex justify-center">
                <div class="md:grid grid-cols-8 grid-rows-2 gap-x-24 gap-y-8 hidden">
                    <!-- <img src="../assets/images/Group_20.svg" alt="" class=" col-span-2 col-start-1 row-span-1 row-start-1 row-end-2 2xl:w-52 xl:w-48 w-40"> -->

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260" class=" col-span-2 col-start-1 row-span-1 row-start-1 row-end-2 2xl:w-56 xl:w-48 w-40">
                        <g id="Group_20" data-name="Group 20" transform="translate(-761 -1707)">
                          <circle id="Ellipse_7" data-name="Ellipse 7" cx="130" cy="130" r="130" transform="translate(761 1707)" fill="#fff" opacity="0.383"/>
                          <text id="Wi-Fi_Gratuit" data-name="Wi-Fi Gratuit" transform="translate(891 1878.341)" fill="#2b1919" font-size="20" font-family="Montserrat-Medium, Montserrat" font-weight="500"><tspan x="-65" y="0">{{ $t('wi-fi') }}</tspan></text>
                          <g id="noun-wifi-1034207" transform="translate(852.9 1770.659)">
                            <path id="Path_100" data-name="Path 100" d="M38.1,20A43.653,43.653,0,0,0,5.573,33.953a2.213,2.213,0,1,0,3.264,2.988A39.174,39.174,0,0,1,38.1,24.414,39.174,39.174,0,0,1,67.363,36.942a2.213,2.213,0,0,0,3.264-2.988A43.653,43.653,0,0,0,38.1,20Zm0,12.505a31.5,31.5,0,0,0-22.941,9.517,2.212,2.212,0,1,0,3.218,3.034A27.017,27.017,0,0,1,38.1,36.919a27.017,27.017,0,0,1,19.723,8.138,2.212,2.212,0,1,0,3.218-3.034A31.5,31.5,0,0,0,38.1,32.505Zm0,11.77a19.117,19.117,0,0,0-13.287,5.034,2.207,2.207,0,1,0,3.034,3.172A14.849,14.849,0,0,1,38.1,48.688a14.849,14.849,0,0,1,10.252,3.793,2.207,2.207,0,1,0,3.034-3.172A19.117,19.117,0,0,0,38.1,44.275Zm0,9.563a5.885,5.885,0,1,0,5.885,5.885A5.919,5.919,0,0,0,38.1,53.837Zm0,4.414a1.471,1.471,0,1,1-1.471,1.471A1.439,1.439,0,0,1,38.1,58.251Z" fill="#9f643c"/>
                          </g>
                        </g>
                      </svg>
                      

                    <!-- <img src="../assets/images/Group_21.svg" alt="" class=" col-span-2 col-start-3 row-span-1 row-start-1 row-end-2 2xl:w-56 xl:w-48 w-40"> -->


                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260" class=" col-span-2 col-start-3 row-span-1 row-start-1 row-end-2 2xl:w-56 xl:w-48 w-40">
                        <g id="Group_21" data-name="Group 21" transform="translate(-1107 -1707)">
                          <circle id="Ellipse_8" data-name="Ellipse 8" cx="130" cy="130" r="130" transform="translate(1107 1707)" fill="#fff" opacity="0.383"/>
                          <text id="Trasee_Obiective_în_Apropiere" data-name="Trasee &amp; Obiective
                      în Apropiere" transform="translate(1237.462 1866.841)" fill="#2b1919" font-size="20" font-family="Montserrat-Medium, Montserrat" font-weight="500"><tspan x="-93" y="0">{{ $t('trasee') }}</tspan><tspan x="-62" y="24">{{ $t('apropiere') }}</tspan></text>
                          <g id="noun-landscape-1041310" transform="translate(1213.355 1778.159)">
                            <path id="Path_105" data-name="Path 105" d="M699.42,556.844h0l6.3,6.3a1.5,1.5,0,0,1-2.117,2.117l-4.182-4.182v8.363a1.5,1.5,0,1,1-2.994,0v-8.363l-4.182,4.182a1.5,1.5,0,0,1-2.117-2.117l6.3-6.3V552.1l-3.433,3.433a1.5,1.5,0,0,1-2.117-2.117l5.55-5.55v-4.749l-1.936,1.936a1.5,1.5,0,0,1-2.117-2.117l4.492-4.492a1.5,1.5,0,0,1,2.117,0l4.492,4.492a1.5,1.5,0,0,1-2.117,2.117l-1.936-1.936v4.749l5.55,5.55a1.5,1.5,0,0,1-2.117,2.117L699.42,552.1Zm-34.308,2.117-2.374-2.374-4.182,4.182a1.5,1.5,0,0,1-2.117-2.117l5.24-5.24a1.5,1.5,0,0,1,2.117,0l3.433,3.433,3.433-3.433a1.5,1.5,0,0,1,2.117,0l12.727,12.727a1.5,1.5,0,0,1-2.117,2.117l-11.668-11.668-8.673,8.673a1.5,1.5,0,0,1-2.117-2.117l4.182-4.182ZM658.3,575.946a1.5,1.5,0,0,1-1.608-2.526,14.075,14.075,0,0,1,3.061-1.326c5.708-1.844,13.133-1.844,22.172,1.169,8.429,2.81,15.2,2.81,20.3,1.16a14.737,14.737,0,0,0,1.846-.725c.286-.138.462-.236.528-.278a1.5,1.5,0,0,1,1.608,2.526,14.075,14.075,0,0,1-3.061,1.326c-5.708,1.844-13.133,1.844-22.172-1.169-8.429-2.81-15.2-2.81-20.3-1.16a14.74,14.74,0,0,0-1.846.725C658.543,575.805,658.366,575.9,658.3,575.946Zm0,7.486a1.5,1.5,0,1,1-1.608-2.526,14.075,14.075,0,0,1,3.061-1.326c5.708-1.844,13.133-1.844,22.172,1.169,8.429,2.81,15.2,2.81,20.3,1.16a14.737,14.737,0,0,0,1.846-.725c.286-.138.462-.236.528-.278a1.5,1.5,0,1,1,1.608,2.526,14.075,14.075,0,0,1-3.061,1.326c-5.708,1.844-13.133,1.844-22.172-1.169-8.429-2.81-15.2-2.81-20.3-1.16a14.74,14.74,0,0,0-1.846.725C658.543,583.292,658.366,583.39,658.3,583.432Zm7.431-34.951a5.24,5.24,0,1,1,5.24-5.24A5.24,5.24,0,0,1,665.732,548.481Zm0-2.995a2.246,2.246,0,1,0-2.246-2.246A2.246,2.246,0,0,0,665.732,545.486Z" transform="translate(-656 -538)" fill="#9f643c"/>
                          </g>
                        </g>
                      </svg>
                      


                    <!-- <img src="../assets/images/Group_22.svg" alt="" class=" col-span-2 col-start-5 row-span-1 row-start-1 row-end-2 2xl:w-56 xl:w-48 w-40"> -->


                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260" class=" col-span-2 col-start-5 row-span-1 row-start-1 row-end-2 2xl:w-56 xl:w-48 w-40">
                        <g id="Group_22" data-name="Group 22" transform="translate(-277 -1707)">
                          <circle id="Ellipse_6" data-name="Ellipse 6" cx="130" cy="130" r="130" transform="translate(277 1707)" fill="#fff" opacity="0.383"/>
                          <text id="Parcare_Gratuită" data-name="Parcare Gratuită" transform="translate(407 1882)" fill="#2b1919" font-size="20" font-family="Montserrat-Medium, Montserrat" font-weight="500"><tspan x="-83" y="0">{{ $t('parcare') }}</tspan></text>
                          <g id="noun-parking-5767234" transform="translate(376 1786.122)">
                            <path id="Path_101" data-name="Path 101" d="M31,1A30,30,0,1,0,61,31,30.034,30.034,0,0,0,31,1Zm0,56A26,26,0,1,1,57,31,26.03,26.03,0,0,1,31,57Zm4-40H25a2,2,0,0,0-2,2V47a2,2,0,0,0,4,0V35h8a6.007,6.007,0,0,0,6-6V23A6.007,6.007,0,0,0,35,17Zm2,12a2,2,0,0,1-2,2H27V21h8a2,2,0,0,1,2,2Z" transform="translate(0 0)" fill="#9f643c"/>
                          </g>
                        </g>
                      </svg>
                      


                    <!-- <img src="../assets/images/Group_24.svg" alt="" class=" col-span-2 col-start-7 row-span-1 row-start-1 row-end-2 2xl:w-56 xl:w-48 w-40"> -->
                    

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260" class=" col-span-2 col-start-7 row-span-1 row-start-1 row-end-2 2xl:w-56 xl:w-48 w-40">
                        <g id="Group_24" data-name="Group 24" transform="translate(-1014 -1397)">
                          <circle id="Ellipse_4" data-name="Ellipse 4" cx="130" cy="130" r="130" transform="translate(1014 1397)" fill="#fff" opacity="0.383"/>
                          <text id="Terasă_Filigorie_cu_Grătar" data-name="Terasă &amp;
                      Filigorie cu Grătar" transform="translate(1144 1559)" fill="#2b1919" font-size="20" font-family="Montserrat-Medium, Montserrat" font-weight="500"><tspan x="-41" y="0">{{ $t('terasa') }}</tspan><tspan x="-90" y="24">{{ $t('filigorie') }}</tspan></text>
                          <g id="noun-grill-1125068" transform="translate(1101.962 1454.608)">
                            <g id="Group_8" data-name="Group 8" transform="translate(20 6.136)">
                              <path id="Path_107" data-name="Path 107" d="M42.359,958.51a1.5,1.5,0,0,0-.891.444,7.259,7.259,0,0,0-1.617,2.152,3.615,3.615,0,0,0-.258,2.269,5.734,5.734,0,0,0,1.9,2.783,2.956,2.956,0,0,1,1.008,1.661c.054.547-.141,1.523-1.5,3.251a1.5,1.5,0,1,0,2.344,1.848c1.618-2.058,2.291-3.813,2.133-5.4a5.773,5.773,0,0,0-1.828-3.415,6.738,6.738,0,0,1-1.148-1.4c-.017-.071-.043-.1.07-.351a4.907,4.907,0,0,1,1.008-1.263,1.5,1.5,0,0,0-1.219-2.573Zm-9,4.491a1.5,1.5,0,0,0-.891.444,7.259,7.259,0,0,0-1.617,2.152,3.615,3.615,0,0,0-.258,2.269,5.734,5.734,0,0,0,1.9,2.783A2.956,2.956,0,0,1,33.5,972.31c.054.547-.141,1.523-1.5,3.251a1.5,1.5,0,1,0,2.344,1.848c1.618-2.058,2.291-3.813,2.133-5.4a5.772,5.772,0,0,0-1.828-3.415,6.741,6.741,0,0,1-1.148-1.4c-.017-.071-.043-.1.07-.351a4.907,4.907,0,0,1,1.008-1.263A1.5,1.5,0,0,0,33.359,963Zm18,0a1.5,1.5,0,0,0-.891.444,7.259,7.259,0,0,0-1.617,2.152,3.615,3.615,0,0,0-.258,2.269,5.734,5.734,0,0,0,1.9,2.783A2.956,2.956,0,0,1,51.5,972.31c.054.547-.141,1.523-1.5,3.251a1.5,1.5,0,1,0,2.344,1.848c1.618-2.058,2.291-3.813,2.133-5.4a5.772,5.772,0,0,0-1.828-3.415,6.741,6.741,0,0,1-1.148-1.4c-.017-.071-.043-.1.07-.351a4.907,4.907,0,0,1,1.008-1.263A1.5,1.5,0,0,0,51.359,963Zm-30,17.238a1.5,1.5,0,0,0-1.359,1.5c0,8.477,5.407,15.73,13.125,19.038l-11.438,21.4a1.5,1.5,0,1,0,2.625,1.427l11.648-21.822a23.792,23.792,0,0,0,5.039.865v14.267a1.5,1.5,0,0,0,3,0v-14.267a23.792,23.792,0,0,0,5.039-.865L60.687,1023.6a1.5,1.5,0,1,0,2.625-1.427l-11.437-21.4C59.593,997.466,65,990.212,65,981.735a1.5,1.5,0,0,0-1.5-1.5h-42Q21.43,980.235,21.359,980.238Zm1.8,2.994H61.836C61,992.4,52.782,999.7,42.5,999.7S24,992.4,23.164,983.232Z" transform="translate(-20 -958.498)" fill="#9f643c"/>
                            </g>
                          </g>
                        </g>
                      </svg>
                      

                    <!--  -->

                    <!-- <img src="../assets/images/Group_25.svg" alt="" class=" col-span-2 col-start-2 row-span-1 row-start-2 row-end-3 2xl:w-56 xl:w-48 w-40"> -->


                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260" class=" col-span-2 col-start-1 row-span-1 row-start-2 row-end-3 2xl:w-56 xl:w-48 w-40">
                        <g id="Group_25" data-name="Group 25" transform="translate(-647 -1397)">
                          <circle id="Ellipse_3" data-name="Ellipse 3" cx="130" cy="130" r="130" transform="translate(647 1397)" fill="#fff" opacity="0.383"/>
                          <text id="Saună_Ciubăr_Jacuzzi" data-name="Saună, Ciubăr
                      &amp; Jacuzzi" transform="translate(777 1558)" fill="#2b1919" font-size="20" font-family="Montserrat-Medium, Montserrat" font-weight="500"><tspan x="-70" y="0">{{ $t('sauna') }}</tspan><tspan x="-46" y="24">{{ $t('jacuzzi') }}</tspan></text>
                          <g id="noun-hot-water-3551404" transform="translate(727 1446)">
                            <g id="Group_10" data-name="Group 10">
                              <path id="Path_109" data-name="Path 109" d="M31.66,38.13C29.58,40.72,27,43.95,27,50v3h6V50c0-3.95,1.47-5.79,3.34-8.13C38.42,39.28,41,36.05,41,30V27H35v3C35,33.95,33.53,35.79,31.66,38.13Z" fill="#9f643c"/>
                              <path id="Path_110" data-name="Path 110" d="M50.66,38.13C48.58,40.72,46,43.95,46,50v3h6V50c0-3.95,1.47-5.79,3.34-8.13C57.42,39.28,60,36.05,60,30V27H54v3C54,33.95,52.53,35.79,50.66,38.13Z" fill="#9f643c"/>
                              <path id="Path_111" data-name="Path 111" d="M71,50c0-3.95,1.47-5.79,3.34-8.13C76.42,39.28,79,36.05,79,30V27H73v3c0,3.95-1.47,5.79-3.34,8.13C67.58,40.72,65,43.95,65,50v3h6Z" fill="#9f643c"/>
                              <path id="Path_112" data-name="Path 112" d="M73.26,64c-1.86-2.12-4.4-5-9.26-5s-7.4,2.9-9.25,5-2.7,3-4.75,3-3-1-4.74-3-4.4-5-9.26-5-7.4,2.9-9.26,5S24,67,22,67H19v6h3c4.86,0,7.4-2.9,9.26-5S34,65,36,65s3,1,4.74,3,4.4,5,9.26,5,7.4-2.9,9.25-5S62,65,64,65s3,1,4.74,3,4.4,5,9.26,5h3V67H78C76,67,75,66,73.26,64Z" fill="#9f643c"/>
                            </g>
                          </g>
                        </g>
                      </svg>
                      

                    <!-- <img src="../assets/images/Group_26.svg" alt="" class=" col-span-2 col-start-4 row-span-1 row-start-2 row-end-3 2xl:w-56 xl:w-48 w-40"> -->

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260" class=" col-span-2 col-start-3 row-span-1 row-start-2 row-end-3 2xl:w-56 xl:w-48 w-40">
                        <g id="Group_26" data-name="Group 26" transform="translate(-277 -1397)">
                          <circle id="Ellipse_2" data-name="Ellipse 2" cx="130" cy="130" r="130" transform="translate(277 1397)" fill="#fff" opacity="0.383"/>
                          <text id="_7_Camere_Duble_Matrimoniale" data-name="7 Camere Duble 
                      Matrimoniale" transform="translate(407 1558)" fill="#2b1919" font-size="20" font-family="Montserrat-Medium, Montserrat" font-weight="500"><tspan x="-84" y="0">{{ $t('camere') }} </tspan><tspan x="-67" y="24">{{ $t('matrimoniale') }}</tspan></text>
                          <g id="noun-bed-1050822" transform="translate(357 1443)">
                            <g id="Group_9" data-name="Group 9" transform="translate(0 -952.362)">
                              <path id="Path_108" data-name="Path 108" d="M19.594,976.362A6.626,6.626,0,0,0,13,982.956V1006.3H10a4.021,4.021,0,0,0-4,4v16.063a2,2,0,0,0,2,2H92a2,2,0,0,0,2-2V1010.3a4.021,4.021,0,0,0-4-4H87V982.956a6.6,6.6,0,0,0-6.563-6.594Zm0,4H80.437A2.565,2.565,0,0,1,83,982.956V1006.3H78.25v-8.406c0-4.015-2.8-7.656-6.688-7.656h-12.5c-3.887,0-6.687,3.642-6.687,7.656v8.406H48.031v-8.406c0-4.01-2.764-7.656-6.656-7.656H28.844c-3.887,0-6.656,3.642-6.656,7.656v8.406H17V982.956A2.6,2.6,0,0,1,19.594,980.362Zm9.25,13.875H41.375c1.268,0,2.656,1.446,2.656,3.656v8.406H26.187v-8.406C26.187,995.688,27.59,994.237,28.844,994.237Zm30.219,0h12.5c1.253,0,2.688,1.45,2.688,3.656v8.406H56.375v-8.406C56.375,995.688,57.809,994.237,59.062,994.237ZM10,1010.3H90v14.063H10Z" fill="#9f643c" fill-rule="evenodd"/>
                            </g>
                          </g>
                        </g>
                      </svg>
                      

                    <!-- <img src="../assets/images/Group_27.svg" alt="" class=" col-span-2 col-start-6 row-span-1 row-start-2 row-end-3 2xl:w-56 xl:w-48 w-40"> -->
                    
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260" class=" col-span-2 col-start-5 row-span-1 row-start-2 row-end-3 2xl:w-56 xl:w-48 w-40">
                        <g id="Group_27" data-name="Group 27" transform="translate(-1348 -1397)">
                          <circle id="Ellipse_5" data-name="Ellipse 5" cx="130" cy="130" r="130" transform="translate(1348 1397)" fill="#fff" opacity="0.383"/>
                          <text id="Baie_Privată" data-name="Baie Privată" transform="translate(1478 1575.298)" fill="#2b1919" font-size="20" font-family="Montserrat-Medium, Montserrat" font-weight="500"><tspan x="-60" y="0">{{ $t('baie') }}</tspan></text>
                          <g id="noun-bath-2206786" transform="translate(1434.404 1463.702)">
                            <g id="Group_7" data-name="Group 7" transform="translate(10 10)">
                              <path id="Path_106" data-name="Path 106" d="M26.8,962.362a10.094,10.094,0,0,0-10.079,10.079v26.037H11.68q-.079,0-.157,0a1.68,1.68,0,0,0-1.522,1.68,22.67,22.67,0,0,0,16.851,21.916c.408,4.108,3.513,7.48,7.507,7.48H52.835c3.994,0,7.1-3.372,7.507-7.48a22.67,22.67,0,0,0,16.851-21.916,1.68,1.68,0,0,0-1.68-1.68H20.079V972.441A6.719,6.719,0,0,1,33.36,971,8.4,8.4,0,0,0,26.8,979.16a1.68,1.68,0,0,0,1.68,1.68H41.916a1.68,1.68,0,0,0,1.68-1.68,8.417,8.417,0,0,0-6.85-8.242,10.093,10.093,0,0,0-9.948-8.557Zm8.4,11.759a4.915,4.915,0,0,1,4.7,3.359H30.5A4.915,4.915,0,0,1,35.2,974.121ZM30.158,984.2a1.68,1.68,0,0,0-1.68,1.68v1.68a1.68,1.68,0,0,0,3.36,0v-1.68A1.68,1.68,0,0,0,30.158,984.2Zm10.079,0a1.68,1.68,0,0,0-1.68,1.68v1.68a1.68,1.68,0,0,0,3.36,0v-1.68A1.68,1.68,0,0,0,40.237,984.2ZM35.2,990.079a1.68,1.68,0,0,0-1.68,1.68v1.68a1.68,1.68,0,1,0,3.36,0v-1.68A1.68,1.68,0,0,0,35.2,990.079Zm-21.68,11.759H73.675a19.175,19.175,0,0,1-19.16,17.638H32.677A19.175,19.175,0,0,1,13.517,1001.838Zm16.824,20.893a22.876,22.876,0,0,0,2.336.1H54.515a22.876,22.876,0,0,0,2.336-.1,4.336,4.336,0,0,1-4.016,3.465H34.357A4.336,4.336,0,0,1,30.341,1022.73Z" transform="translate(-10 -962.362)" fill="#9f643c"/>
                            </g>
                          </g>
                        </g>
                      </svg>
                      
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260" class=" col-span-2 col-start-7 row-span-1 row-start-2 row-end-3 2xl:w-56 xl:w-48 w-40">
                        <g id="Group_339" data-name="Group 339" transform="translate(-1529 -1841)">
                          <g id="Group_348" data-name="Group 348">
                            <circle id="Ellipse_8" data-name="Ellipse 8" cx="130" cy="130" r="130" transform="translate(1529 1841)" fill="#fff" opacity="0.383"/>
                            <text id="Piscină_Încălzită_la_30_de_Grade" data-name="Piscină Încălzită
                      la 30 de Grade" transform="translate(1659.463 2000.84)" fill="#2b1919" font-size="20" font-family="Montserrat-Medium, Montserrat" font-weight="500"><tspan x="-81" y="0">{{ $t('piscina') }}</tspan><tspan x="-72" y="24">{{ $t('incalzita') }}</tspan></text>
                            <g id="Group_338" data-name="Group 338" transform="translate(1622.939 1908.836)">
                              <path id="Path_11665" data-name="Path 11665" d="M114.115,224.2c2.739.167,4.519,1.538,6.4,2.99,2.054,1.583,4.383,3.375,8.029,3.5.141.005.28.008.417.008,3.838,0,6.294-1.833,8.47-3.456s4.082-3.046,7.241-2.886c2.942.149,4.805,1.614,6.779,3.165,2.156,1.694,4.386,3.446,7.982,3.589.153.006.3.009.456.009,4.335,0,8.708-2.455,13-7.3l-1.9-1.687c-3.792,4.279-7.527,6.445-11.105,6.445-.117,0-.234,0-.351-.006-2.474-.1-4.12-1.191-5.869-2.545h.352l-.037-22.377-.005-.111a1.987,1.987,0,0,1,.558-1.583,1.412,1.412,0,0,1,1.036-.467,1.367,1.367,0,0,1,.878.524,1.987,1.987,0,0,1,.437,1.5l2.527.275a4.573,4.573,0,0,0-1.007-3.4,3.693,3.693,0,0,0-5.678-.225,4.524,4.524,0,0,0-1.289,3.536l.008,4.288H141.31v2.542h10.144l.009,5.049-9.9.022.005,2.542,9.9-.022.01,6.217a12.049,12.049,0,0,0-6.671-2.525,10.6,10.6,0,0,0-4.887.878l.057-19.144-.015-.1a1.8,1.8,0,0,1,.379-1.433,1.332,1.332,0,0,1,.909-.529,1.351,1.351,0,0,1,.985.464,1.794,1.794,0,0,1,.482,1.439l2.528.264a4.352,4.352,0,0,0-1.166-3.453,3.679,3.679,0,0,0-5.714.211,4.365,4.365,0,0,0-.932,3.322l-.061,20.409c-.506.348-.988.707-1.456,1.056-2.2,1.639-4.1,3.051-7.281,2.945-2.828-.1-4.56-1.429-6.563-2.973s-4.284-3.3-7.8-3.514c-4.631-.281-9.484,2.188-14.421,7.34l1.836,1.759C106.08,226.178,110.265,223.973,114.115,224.2Z" transform="translate(-99.85 -198.946)" fill="#9f643c"/>
                              <path id="Path_11666" data-name="Path 11666" d="M196.3,452.6a1.823,1.823,0,0,0,.114-1.819,1.433,1.433,0,0,0-1.82-.517,7.843,7.843,0,0,0-.753.355,10.784,10.784,0,0,1-5.473,1.411c-2.816-.091-4.467-1.467-6.214-2.924s-3.7-3.085-6.8-3.253c-3.919-.212-6.552,1.691-8.872,3.369-2.18,1.578-4.241,3.067-7.3,2.962-2.72-.093-4.51-1.5-6.406-2.985-2.061-1.617-4.4-3.45-8.019-3.5h-.167c-3.447,0-6.9,1.569-10.248,4.663l-.835.77.672.916c1.307,1.782,12.982,16.953,31.24,16.953q.871,0,1.762-.047a38.636,38.636,0,0,0,19.971-7.311C192.258,458.022,195.472,454.173,196.3,452.6Zm-29.252,13.81c-7.547.4-14.872-1.963-21.768-7.029a45.874,45.874,0,0,1-8.339-7.922c2.577-2.139,5.146-3.222,7.651-3.222h.127c2.764.041,4.489,1.395,6.486,2.962,2.034,1.6,4.339,3.4,7.888,3.525,3.938.133,6.563-1.767,8.881-3.444,2.265-1.639,4.22-3.055,7.243-2.891,2.251.122,3.667,1.3,5.306,2.667,1.908,1.59,4.07,3.392,7.759,3.512a12.136,12.136,0,0,0,3.655-.446,40.323,40.323,0,0,1-5.7,5.043A36.278,36.278,0,0,1,167.05,466.407Z" transform="translate(-129.231 -414.324)" fill="#9f643c"/>
                            </g>
                          </g>
                        </g>
                      </svg>
                      
                </div>
                <div class="grid grid-cols-3 grid-rows-8 md:hidden gap-y-8 mx-16">
                    <div class="grid grid-cols-3 items-center col-span-3 justify-center gap-2 w-full">
                        <img src="../assets/images/bed.svg" alt="Bed" class=" row-span-1 row-start-1 col-span-1 col-start-1">
                        <p class=" text-maro-500 col-span-2 col-start-2 font-semibold">{{ $t('double_rooms') }}</p>
                    </div>
                    
                    <div class=" grid grid-cols-3  items-center col-span-3 justify-between gap-2 w-full">
                        <img src="../assets/images/grill.svg" alt="Bed" class=" row-span-1 row-start-1 col-span-1 col-start-1">
                        <p class=" text-maro-500 col-span-2 col-start-2 font-semibold">{{ $t('terrace_grill') }}</p>
                    </div>

                    <div class=" grid grid-cols-3  items-center col-span-3 justify-between gap-2 w-full">
                        <img src="../assets/images/water.svg" alt="Bed" class=" row-span-1 row-start-1 col-span-1 col-start-1">
                        <p class=" text-maro-500 col-span-2 col-start-2 font-semibold">{{ $t('sauna_jacuzzi') }}</p>
                    </div>

                    <div class="grid grid-cols-3  items-center col-span-3 justify-between gap-2 w-full">
                        <img src="../assets/images/bath.svg" alt="Bed" class=" row-span-1 row-start-1 col-span-1 col-start-1">
                        <p class=" text-maro-500 col-span-2 col-start-2 font-semibold">{{ $t('private_bathroom') }}</p>
                    </div>

                    <div class="grid grid-cols-3  items-center col-span-3 justify-between gap-2 w-full">
                        <img src="../assets/images/parking.svg" alt="Bed" class=" row-span-1 row-start-1 col-span-1 col-start-1">
                        <p class=" text-maro-500 col-span-2 col-start-2 font-semibold">{{ $t('free_parking') }}</p>
                    </div>

                    <div class="grid grid-cols-3 items-center col-span-3 justify-between gap-2 w-full">
                        <img src="../assets/images/wifi.svg" alt="Bed" class=" row-span-1 row-start-1 col-span-1 col-start-1">
                        <p class=" text-maro-500 col-span-2 col-start-2 font-semibold">{{ $t('free_wifi') }}</p>
                    </div>

                    <div class="grid grid-cols-3 items-center col-span-3 justify-between gap-2 w-full">
                        <img src="../assets/images/landscape.svg" alt="Bed" class=" row-span-1 row-start-1 col-span-1 col-start-1">
                        <p class=" text-maro-500 col-span-2 col-start-2 font-semibold">{{ $t('trails_attractions') }}</p>
                    </div>

                    <div class="grid grid-cols-3 items-center col-span-3 justify-between gap-2 w-full">
                        <img src="../assets/images/piscina.svg" alt="Bed" class=" row-span-1 row-start-1 col-span-1 col-start-1">
                        <p class=" text-maro-500 col-span-2 col-start-2 font-semibold">{{ $t('pool') }}</p>
                    </div>

                </div>
            </div>
        </div>
        <div id="despre" class="bg-maro-700" data-scroll-section>
            <div class="md:mx-40 2xl:mx-80 mx-12">
                <div class="grid grid-cols-3 md:grid-rows-1 auto-rows-auto md:gap-36">
                    <div class=" md:col-span-2 col-span-3 col-start-1 flex flex-col gap-y-6 md:py-20 py-16">
                        <h4 class=" font-grafolita md:text-3xl text-xl text-white">{{ $t('learn_more') }}</h4>
                        <h2 class=" font-grafolita md:text-7xl text-5xl text-white">{{ $t('about_us') }}</h2>
                        <p class="text-white mt-10" v-html="$t('about_us_description')"></p>
                        <a @click.prevent="emitScrollToSection('locatie')" class="bg-maro-500 hover:bg-maro-500 text-white font-light 2xl:py-3 2xl:px-13 py-3 px-12 rounded my-6 2xl:text-xl text-base w-fit cursor-pointer">
                            {{ $t('contact_button') }}
                        </a>
                    </div>
                    <div class=" md:col-span-1 col-span-3 md:col-start-3 flex flex-col items-center h-full justify-center md:row-start-1 row-start-2 md:mb-0 mb-44">
                        <img src="../assets/images/CertificatBun.png" alt="certificat">
                    </div>
                </div>
            </div>
        </div>
        <div id="galerie" class=" bg-bej-500" data-scroll-section>
            <div class="flex flex-col items-center gap-28 py-16">
                <h2 class=" font-grafolita text-7xl text-maro-600">{{ $t('galerie') }}</h2>
                <Swiper
                :modules="modules"
                :slides-per-view="3"
                :space-between="5"
                :loopedSlides="1"
                :loop="true"
                :lazy="true"
                :navigation="true"
                :pagination="false"
                :scrollbar="{ draggable: true}"
                :breakpoints="{
                    320: {  // for screens of width >= 320px
                    slidesPerView: 2,
                    
                    spaceBetween: 5
                    },
                    768: {  // for screens of width >= 768px
                    slidesPerView: 2,
                    
                    spaceBetween: 10
                    },
                    1024: {  // for screens of width >= 1024px
                    
                    slidesPerView: 3,
                    spaceBetween: 15
                    },
                    1440: {  // for screens of width >= 1440px
                    
                    slidesPerView: 4,
                    spaceBetween: 20
                    }
                }"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
                @slideChangeTransitionStart="onSlideChangeTransitionStart"
                    class="mySwiper w-full h-80 flex justify-center items-center"
                >
                
                <!-- <SwiperSlide>
                    <div class="flex flex-col h-full gap-y-4">
                        <img src="../assets/images/p3.png" alt="" class=" md:w-1/2 md:h-1/2 h-full w-full md:object-cover md:object-center">
                        <img src="../assets/images/p3.png" alt="" class=" md:w-1/2 md:h-1/2 h-full w-full md:object-cover md:object-center">
                    </div>
                </SwiperSlide>
               
                <SwiperSlide>
                    <img src="../assets/images/p5.png" alt="">
                </SwiperSlide>
                
                <SwiperSlide>
                    <img src="../assets/images/p10.png" alt="">
                </SwiperSlide>
                <SwiperSlide>
                    <img src="../assets/images/p11.png" alt="">
                </SwiperSlide> -->
                <SwiperSlide>
                    <div class="flex h-full justify-center">
                        <img loading="lazy" src="../assets/images/poza_1.jpg" alt="" class=" md:w-full h-full w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>

                <SwiperSlide>
                    <div class="flex flex-col h-full gap-y-4 md:items-start items-center">
                        <img loading="lazy" src="../assets/images/poza_2.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                        <img loading="lazy" src="../assets/images/poza_3.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="flex flex-col h-full gap-y-4 md:items-start items-center">
                        <img loading="lazy" src="../assets/images/poza_4.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                        <img loading="lazy" src="../assets/images/poza_5.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>
                
                <SwiperSlide>
                    <div class="flex h-full justify-center">
                        <img loading="lazy" src="../assets/images/poza_6.jpg" alt="" class=" md:w-full h-full w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>

                <SwiperSlide>
                    <div class="flex h-full justify-center">
                        <img loading="lazy" src="../assets/images/poza_7.jpg" alt="" class=" md:w-full h-full w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="flex flex-col h-full gap-y-4 md:items-start items-center">
                        <img loading="lazy" src="../assets/images/poza_8.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                        <img loading="lazy" src="../assets/images/poza_9.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>

                <SwiperSlide>
                    <div class="flex h-full justify-center">
                        <img loading="lazy" src="../assets/images/poza_10.jpg" alt="" class=" md:w-full h-full w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>

                <SwiperSlide>
                    <div class="flex flex-col h-full gap-y-4 md:items-start items-center">
                        <img loading="lazy" src="../assets/images/poza_11.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                        <img loading="lazy" src="../assets/images/poza_12.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="flex flex-col h-full gap-y-4 md:items-start items-center">
                        <img loading="lazy" src="../assets/images/poza_13.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                        <img loading="lazy" src="../assets/images/poza_14.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>
                
                <SwiperSlide>
                    <div class="flex h-full justify-center">
                        <img loading="lazy" src="../assets/images/poza_15.jpg" alt="" class=" md:w-full h-full w-11/12 object-cover object-center">
                    </div>
                    
                </SwiperSlide>

                <SwiperSlide>
                    <div class="flex h-full justify-center">
                        <img loading="lazy" src="../assets/images/poza_16.jpg" alt="" class=" md:w-full h-full w-11/12 object-cover object-center">
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="flex flex-col h-full gap-y-4 md:items-start items-center">
                        <img loading="lazy" src="../assets/images/poza_17.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                        <img loading="lazy" src="../assets/images/poza_18.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>



                <SwiperSlide>
                    <div class="flex h-full justify-center">
                        <img loading="lazy" src="../assets/images/poza_19.jpg" alt="" class=" md:w-full h-full w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>

                <SwiperSlide>
                    <div class="flex flex-col h-full gap-y-4 md:items-start items-center">
                        <img loading="lazy" src="../assets/images/poza_20.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                        <img loading="lazy" src="../assets/images/poza_21.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="flex flex-col h-full gap-y-4 md:items-start items-center">
                        <img loading="lazy" src="../assets/images/poza_22.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                        <img loading="lazy" src="../assets/images/poza_23.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>
                
                <SwiperSlide>
                    <div class="flex h-full justify-center">
                        <img loading="lazy" src="../assets/images/poza_24.jpg" alt="" class=" md:w-full h-full w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>

                <SwiperSlide>
                    <div class="flex h-full justify-center">
                        <img loading="lazy" src="../assets/images/poza_25.jpg" alt="" class=" md:w-full h-full w-11/12 object-cover object-center">
                    </div>
                    <div
                        class="swiper-lazy-preloader swiper-lazy-preloader-white"
                    ></div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="flex flex-col h-full gap-y-4 md:items-start items-center">
                        <img loading="lazy" src="../assets/images/poza_26.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                        <img loading="lazy" src="../assets/images/poza_27.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                    </div>
                </SwiperSlide>


                <SwiperSlide>
                    <div class="flex h-full justify-center">
                        <img loading="lazy" src="../assets/images/poza_28.jpg" alt="" class=" md:w-full h-full w-11/12 object-cover object-center">
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div class="flex flex-col h-full gap-y-4 md:items-start items-center">
                        <img loading="lazy" src="../assets/images/poza_29.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                        <img loading="lazy" src="../assets/images/poza_30.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="flex flex-col h-full gap-y-4 md:items-start items-center">
                        <img loading="lazy" src="../assets/images/poza_31.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                        <img loading="lazy" src="../assets/images/poza_32.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                    </div>
                </SwiperSlide>
                
                <SwiperSlide>
                    <div class="flex h-full justify-center">
                        <img loading="lazy" src="../assets/images/poza_33.jpg" alt="" class=" md:w-full h-full w-11/12 object-cover object-center">
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div class="flex h-full justify-center">
                        <img loading="lazy" src="../assets/images/poza_34.jpg" alt="" class=" md:w-full h-full w-11/12 object-cover object-center">
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="flex flex-col h-full gap-y-4 md:items-start items-center">
                        <img loading="lazy" src="../assets/images/poza_35.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                        <img loading="lazy" src="../assets/images/poza_36.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div class="flex flex-col h-full gap-y-4 md:items-start items-center">
                        <img loading="lazy" src="../assets/images/poza_37.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                        <img loading="lazy" src="../assets/images/poza_38.jpg" alt="" class=" md:w-full md:h-1/2 h-1/2 w-11/12 object-cover object-center">
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div class="flex h-full justify-center">
                        <img loading="lazy" src="../assets/images/poza_39.jpg" alt="" class=" md:w-full h-full w-11/12 object-cover object-center">
                    </div>
                </SwiperSlide>
                
                
                </Swiper>
                
                
            </div>
        </div>
        <div id="atractii" class=" bg-maro-700" data-scroll-section>
            <div class=" md:mx-40 2xl:mx-80 flex flex-col items-center py-16 box-border">
                <h3 class=" font-grafolita text-white text-3xl">{{ $t('attractions.title') }}</h3>
                <h2 class=" font-grafolita md:text-7xl text-5xl text-white md:text-left text-center">{{ $t('attractions.subtitle') }}</h2>
                <div class="md:grid grid-cols-4 md:grid-rows-3 gap-x-36 md:gap-y-36 gap-y-14 md:mt-28 mt-20 flex flex-col mx-8">
                    <div class=" col-span-2 flex flex-col row-span-1 row-start-1 col-start-1 gap-y-8 justify-center">
                        <h3 class=" font-grafolita text-white text-4xl">{{ $t('attractions.attractions_details[0].name') }}</h3>
                        <p class=" font-sans text-white text-base" v-html=" $t('attractions.attractions_details[0].description') "></p>
                    </div>
                    <div class=" col-span-2 row-start-1 row-span-1 col-start-3">
                        <img src="../assets/images/atractie2.png" alt="Muzeul de icoane pe sticla Pr. Zosim Oancea">
                    </div>
                    <!--  -->
                    <!--  -->
                    <div class=" col-span-2 row-start-2 row-span-1 col-start-1 md:block hidden">
                        <img src="../assets/images/atractie1.png" alt="Schitul Sibiel">
                    </div>
                    <div class=" col-span-2 flex flex-col row-span-1 row-start-2 col-start-3 gap-y-8 justify-center">
                        <h3 class=" font-grafolita text-white text-4xl">{{ $t('attractions.attractions_details[1].name') }}</h3>
                        <p class=" font-sans text-white text-base" v-html=" $t('attractions.attractions_details[1].description') "></p>
                    </div>
                    <div class=" col-span-2 row-start-2 row-span-1 col-start-1 md:hidden block">
                        <img src="../assets/images/atractie1.png" alt="Schitul Sibiel">
                    </div>
                    <!--  -->
                    <!--  -->
                    <div class=" col-span-2 flex flex-col row-span-1 row-start-3 col-start-1 gap-y-8 justify-center">
                        <h3 class="font-grafolita text-white text-4xl">{{ $t('attractions.attractions_details[2].name') }}</h3>
                        <p class=" font-sans text-white text-base" v-html=" $t('attractions.attractions_details[2].description') "></p>
                    </div>
                    <div class=" col-span-2 row-start-3 col-start-3 row-span-1">
                        <img src="../assets/images/atractie3.png" alt="Biserica Sfânta Treime din Sibiel">
                    </div>
                </div>
                
            </div>
        </div>
        <div id="reviews" class=" bg-bej-500 md:block" data-scroll-section>
            <div class="mx-8 md:mx-40 2xl:mx-80 flex flex-col py-16 md:items-center">
                <h3 class="font-grafolita text-maro-600 text-3xl">{{ $t('ce_spun') }}</h3>
                <h2 class=" font-grafolita text-7xl text-maro-600">{{ $t('clientii') }}</h2>
                

                <Swiper
                :modules="modules"
                :slides-per-view="1"
                :space-between="0"
                :loop="true"
                :navigation="true"
               
                
                :breakpoints="{
                    320: { slidesPerView: 1, spaceBetween: 40 },
                    768: { slidesPerView: 1, spaceBetween: 10 },
                    1024: { slidesPerView: 1, spaceBetween: 0 }
                }"
                class="mySwiper w-full h-fit"
            >
                <SwiperSlide class="reviewSlide">
                    <div class="bg-white md:w-11/12 w-full lg:h-96 h-fit mt-20 flex flex-col xl:px-28 py-12 px-4 box-border">
                        <div class="grid grid-cols-7 lg:grid-rows-8 auto-rows-auto h-full">
                            <img src="" alt="" class=" col-span-1 row-span-1 col-start-1 row-start-1 md:block hidden">
                            <div class="flex flex-col md:col-span-2 col-span-4 row-start-1 md:col-start-2 col-start-1">
                                <h4 class=" font-sans text-maro-500 text-xl font-semibold">Elena Sevastianov</h4>
                                <p class="font-sans text-maro-500 text-base font-normal">acum 3 ani pe Google</p>
                            </div>
                            <img src="../assets/images/stele.svg" alt="review" class="col-span-2 row-span-1 col-start-6 row-start-1">
                            <!--  -->
                            <!--  -->
                            <p class=" font-sans font-medium col-span-1 row-span-1 md:col-start-2 col-start-1 row-start-3 text-maro-500 opacity-75 text-ellipsis whitespace-nowrap">Vacanță</p>
                            <p class=" font-sans font-medium md:col-span-5 col-span-7 row-span-4 md:col-start-2 col-start-1 row-start-4 text-maro-500 md:mt-0 mt-8">Ne-am simtit minunat aici. Pensiunea e superba, foarte curata si decorata cu bun gust. Curte generoasa cu gazon, pomi, foisor, sezlonguri si pufuri, ciubar. Gazdele foarte amabile si saritoare. Camera mare, luminoasa, pat super confortabil, baie spatioasa cu design modern.</p>
                        
                            <!--  -->
                            <!--  -->
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-2 col-start-1 font-semibold md:mt-0 mt-8">Camere: <span class=" font-medium">5,0</span></p>
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-4 col-start-3 font-semibold md:mt-0 mt-8">Serviciu: <span class=" font-medium">5,0</span></p>
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-6 col-start-5 font-semibold md:mt-0 mt-8">Locatie: <span class=" font-medium">5,0</span></p>
                        </div>
                        
                    </div>
                </SwiperSlide>
                <SwiperSlide class="reviewSlide">
                    <div class="bg-white md:w-11/12 w-full lg:h-96 h-fit mt-20 flex flex-col xl:px-28 py-12 px-4 box-border">
                        <div class="grid grid-cols-7 lg:grid-rows-8 auto-rows-auto h-full">
                            <img src="" alt="" class=" col-span-1 row-span-1 col-start-1 row-start-1 md:block hidden">
                            <div class="flex flex-col md:col-span-2 col-span-4 row-start-1 md:col-start-2 col-start-1">
                                <h4 class=" font-sans text-maro-500 text-xl font-semibold">Iulian Matican                                 </h4>
                                <p class="font-sans text-maro-500 text-base font-normal">acum 2 ani pe Google</p>
                            </div>
                            <img src="../assets/images/stele.svg" alt="review" class="col-span-2 row-span-1 col-start-6 row-start-1">
                            <!--  -->
                            <!--  -->
                            <p class=" font-sans font-medium col-span-1 row-span-1 md:col-start-2 col-start-1 row-start-3 text-maro-500 opacity-75 text-ellipsis whitespace-nowrap">Vacanță</p>
                            <p class=" font-sans font-medium md:col-span-5 col-span-7 row-span-4 md:col-start-2 col-start-1 row-start-4 text-maro-500 md:mt-0 mt-8">The Best, pentru conditiile pe care le are raportat la pret ...sunt excelente, camere mari si aerisite, paturii confortabile, sauna si ciubarul sunt de vis, mai ales intr.o zi de toamna friguroasa, ai tot ce iti doresti! Multumim si o sa revenim cu drag!</p>
                        
                            <!--  -->
                            <!--  -->
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-2 col-start-1 font-semibold md:mt-0 mt-8">Camere: <span class=" font-medium">5,0</span></p>
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-4 col-start-3 font-semibold md:mt-0 mt-8">Serviciu: <span class=" font-medium">5,0</span></p>
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-6 col-start-5 font-semibold md:mt-0 mt-8">Locatie: <span class=" font-medium">5,0</span></p>
                        </div>
                        
                    </div>
                </SwiperSlide>
                <SwiperSlide class="reviewSlide">
                    <div class="bg-white md:w-11/12 w-full lg:h-96 h-fit mt-20 flex flex-col xl:px-28 py-12 px-4 box-border">
                        <div class="grid grid-cols-7 lg:grid-rows-8 auto-rows-auto h-full">
                            <img src="" alt="" class=" col-span-1 row-span-1 col-start-1 row-start-1 md:block hidden">
                            <div class="flex flex-col md:col-span-2 col-span-4 row-start-1 md:col-start-2 col-start-1">
                                <h4 class=" font-sans text-maro-500 text-xl font-semibold">Marius Hertoiu                                 </h4>
                                <p class="font-sans text-maro-500 text-base font-normal">acum 2 ani pe Google</p>
                            </div>
                            <img src="../assets/images/stele.svg" alt="review" class="col-span-2 row-span-1 col-start-6 row-start-1">
                            <!--  -->
                            <!--  -->
                            <p class=" font-sans font-medium col-span-1 row-span-1 md:col-start-2 col-start-1 row-start-3 text-maro-500 opacity-75 text-ellipsis whitespace-nowrap">Vacanță in familie</p>
                            <p class=" font-sans font-medium md:col-span-5 col-span-7 row-span-4 md:col-start-2 col-start-1 row-start-4 text-maro-500 md:mt-0 mt-8">O locatie unde te poti bucura de ospitalitate,liniste si relaxare. Ideala pentru familile cu copii sau pentru un refugiu linistit pentru cateva zile.</p>
                        
                            <!--  -->
                            <!--  -->
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-2 col-start-1 font-semibold md:mt-0 mt-8">Camere: <span class=" font-medium">5,0</span></p>
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-4 col-start-3 font-semibold md:mt-0 mt-8">Serviciu: <span class=" font-medium">5,0</span></p>
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-6 col-start-5 font-semibold md:mt-0 mt-8">Locatie: <span class=" font-medium">5,0</span></p>
                        </div>
                        
                    </div>
                </SwiperSlide>
                <SwiperSlide class="reviewSlide">
                    <div class="bg-white md:w-11/12 w-full lg:h-96 h-fit mt-20 flex flex-col xl:px-28 py-12 px-4 box-border">
                        <div class="grid grid-cols-7 lg:grid-rows-8 auto-rows-auto h-full">
                            <img src="" alt="" class=" col-span-1 row-span-1 col-start-1 row-start-1 md:block hidden">
                            <div class="flex flex-col md:col-span-2 col-span-4 row-start-1 md:col-start-2 col-start-1">
                                <h4 class=" font-sans text-maro-500 text-xl font-semibold">Florin                                </h4>
                                <p class="font-sans text-maro-500 text-base font-normal">acum 1 ani pe Google</p>
                            </div>
                            <img src="../assets/images/stele.svg" alt="review" class="col-span-2 row-span-1 col-start-6 row-start-1">
                            <!--  -->
                            <!--  -->
                            <p class=" font-sans font-medium col-span-1 row-span-1 md:col-start-2 col-start-1 row-start-3 text-maro-500 opacity-75 text-ellipsis whitespace-nowrap">Vacanță</p>
                            <p class=" font-sans font-medium md:col-span-5 col-span-7 row-span-4 md:col-start-2 col-start-1 row-start-4 text-maro-500 md:mt-0 mt-8">O experienta plăcută, gazdele primitoare, locație superba, curat, camere mari, ciubarul din curte de vis.Cu siguranță ne vom întoarce. Recomand...</p>
                        
                            <!--  -->
                            <!--  -->
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-2 col-start-1 font-semibold md:mt-0 mt-8">Camere: <span class=" font-medium">5,0</span></p>
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-4 col-start-3 font-semibold md:mt-0 mt-8">Serviciu: <span class=" font-medium">5,0</span></p>
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-6 col-start-5 font-semibold md:mt-0 mt-8">Locatie: <span class=" font-medium">5,0</span></p>
                        </div>
                        
                    </div>
                </SwiperSlide>
                <SwiperSlide class="reviewSlide">
                    <div class="bg-white md:w-11/12 w-full lg:h-96 h-fit mt-20 flex flex-col xl:px-28 py-12 px-4 box-border">
                        <div class="grid grid-cols-7 lg:grid-rows-8 auto-rows-auto h-full">
                            <img src="" alt="" class=" col-span-1 row-span-1 col-start-1 row-start-1 md:block hidden">
                            <div class="flex flex-col md:col-span-2 col-span-4 row-start-1 md:col-start-2 col-start-1">
                                <h4 class=" font-sans text-maro-500 text-xl font-semibold"> Iulia Adela                               </h4>
                                <p class="font-sans text-maro-500 text-base font-normal">acum 2 ani pe Google</p>
                            </div>
                            <img src="../assets/images/stele.svg" alt="review" class="col-span-2 row-span-1 col-start-6 row-start-1">
                            <!--  -->
                            <!--  -->
                            <p class=" font-sans font-medium col-span-1 row-span-1 md:col-start-2 col-start-1 row-start-3 text-maro-500 opacity-75 text-ellipsis whitespace-nowrap">Vacanță</p>
                            <p class=" font-sans font-medium md:col-span-5 col-span-7 row-span-4 md:col-start-2 col-start-1 row-start-4 text-maro-500 md:mt-0 mt-8">Vila este superba, la nivelul unui Hotel de 5* din afara tarii. Camerele sunt extrem de spatioase, curate. Totul este la superlativ. Recomand cu caldura, rar intalnesti oameni ca dl Paul si dna Dana. Multumim!!!!</p>
                        
                            <!--  -->
                            <!--  -->
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-2 col-start-1 font-semibold md:mt-0 mt-8">Camere: <span class=" font-medium">5,0</span></p>
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-4 col-start-3 font-semibold md:mt-0 mt-8">Serviciu: <span class=" font-medium">5,0</span></p>
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-6 col-start-5 font-semibold md:mt-0 mt-8">Locatie: <span class=" font-medium">5,0</span></p>
                        </div>
                        
                    </div>
                </SwiperSlide>
                <SwiperSlide class="reviewSlide">
                    <div class="bg-white md:w-11/12 w-full lg:h-96 h-fit mt-20 flex flex-col xl:px-28 py-12 px-4 box-border">
                        <div class="grid grid-cols-7 lg:grid-rows-8 auto-rows-auto h-full">
                            <img src="" alt="" class=" col-span-1 row-span-1 col-start-1 row-start-1 md:block hidden">
                            <div class="flex flex-col md:col-span-2 col-span-4 row-start-1 md:col-start-2 col-start-1">
                                <h4 class=" font-sans text-maro-500 text-xl font-semibold">Aurora Barzu                              </h4>
                                <p class="font-sans text-maro-500 text-base font-normal">acum 2 ani pe Google</p>
                            </div>
                            <img src="../assets/images/stele.svg" alt="review" class="col-span-2 row-span-1 col-start-6 row-start-1">
                            <!--  -->
                            <!--  -->
                            <p class=" font-sans font-medium col-span-1 row-span-1 md:col-start-2 col-start-1 row-start-3 text-maro-500 opacity-75 text-ellipsis whitespace-nowrap">Vacanță</p>
                            <p class=" font-sans font-medium md:col-span-5 col-span-7 row-span-4 md:col-start-2 col-start-1 row-start-4 text-maro-500 md:mt-0 mt-8">Locația este foarte frumoasa, camerele foarte curate, spațioase și elegante.
                                Gazdele sunt ospitaliere și atente la detaliile care fac diferenta între a fi cazat și a te simți bine intr-o vila.
                                Mulțumim dle Paul!
                                Multumim Dna Dana!
                                Sunteți deosebiți iar noi am petrecut câteva zile la superlativ.</p>
                        
                            <!--  -->
                            <!--  -->
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-2 col-start-1 font-semibold md:mt-0 mt-8">Camere: <span class=" font-medium">5,0</span></p>
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-4 col-start-3 font-semibold md:mt-0 mt-8">Serviciu: <span class=" font-medium">5,0</span></p>
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-6 col-start-5 font-semibold md:mt-0 mt-8">Locatie: <span class=" font-medium">5,0</span></p>
                        </div>
                        
                    </div>
                </SwiperSlide>
                <SwiperSlide class="reviewSlide">
                    <div class="bg-white md:w-11/12 w-full lg:h-96 h-fit mt-20 flex flex-col xl:px-28 py-12 px-4 box-border">
                        <div class="grid grid-cols-7 lg:grid-rows-8 auto-rows-auto h-full">
                            <img src="" alt="" class=" col-span-1 row-span-1 col-start-1 row-start-1 md:block hidden">
                            <div class="flex flex-col md:col-span-2 col-span-4 row-start-1 md:col-start-2 col-start-1">
                                <h4 class=" font-sans text-maro-500 text-xl font-semibold">Sorin                                 </h4>
                                <p class="font-sans text-maro-500 text-base font-normal">acum 2 ani pe Google</p>
                            </div>
                            <img src="../assets/images/stele.svg" alt="review" class="col-span-2 row-span-1 col-start-6 row-start-1">
                            <!--  -->
                            <!--  -->
                            <p class=" font-sans font-medium col-span-1 row-span-1 md:col-start-2 col-start-1 row-start-3 text-maro-500 opacity-75 text-ellipsis whitespace-nowrap">Vacanță</p>
                            <p class=" font-sans font-medium md:col-span-5 col-span-7 row-span-4 md:col-start-2 col-start-1 row-start-4 text-maro-500 md:mt-0 mt-8">Am petrecut cateva zile de vis la pensiunea Crisalia, in satul Sibiel, la poalele muntilor Cibinului. Gazdele primitoare, Paul si Dana, sunt oameni tare faini, calzi, modesti si harnici care au creat un ambient placut si relaxant ce imbina confortul unei cazari moderne cu traditionalul satului romanesc. Meritati sa va răsfățați intr-o oaza de liniste. Cu siguranță vom reveni cu drag!
                            </p>
                        
                            <!--  -->
                            <!--  -->
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-2 col-start-1 font-semibold md:mt-0 mt-8">Camere: <span class=" font-medium">5,0</span></p>
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-4 col-start-3 font-semibold md:mt-0 mt-8">Serviciu: <span class=" font-medium">5,0</span></p>
                            <p class="row-start-8 row-span-1 col-span-1 md:col-start-6 col-start-5 font-semibold md:mt-0 mt-8">Locatie: <span class=" font-medium">5,0</span></p>
                        </div>
                        
                    </div>
                </SwiperSlide>
                <!-- Repeat SwiperSlide for each review -->
            </Swiper>

            </div>
        </div>
        <!-- <div id="oferta" class=" bg-maro-700 text-white">
            <div class=" mx-8 md:mx-40 2xl:mx-80 flex flex-col gap-y-8 pt-24 pb-14 md:items-start items-center">
                <h3 class=" font-grafolita md:text-3xl text-2xl">Sa iti prezentam</h3>
                <h2 class=" font-grafolita md:text-7xl text-6xl">Oferta noastra</h2>
                
                <div class="md:grid grid-cols-5 mt-16 flex justify-center">
                    <h1 class=" col-span-3 md:col-start-2 col-start-1 flex items-start font-sans text-xl md:text-3xl">RON <span class=" self-end text-8xl md:text-9xl font-grafolita md:mt-12 m-4">2400</span>/noapte</h1>
                </div>
                <p class=" font-sans text-base opacity-75 mt-28">*Prețul pentru închirierea întregii vile în extrasezon, pentru o perioadă minimă de două nopți</p>
                <a href="#locatie" class="bg-maro-500 hover:bg-maro-500 text-white font-light 2xl:py-3 2xl:px-13 py-3 px-12 rounded my-6 2xl:text-xl text-base w-fit self-start">
                            Contactați-ne
                </a>
            </div>
        </div> -->
        <div id="oferta" class=" bg-maro-700 text-white" data-scroll-section>
            <div class=" mx-8 md:mx-40 2xl:mx-80 flex flex-col gap-y-8 pt-24 pb-14 md:items-start items-center">
                <h3 class=" font-grafolita md:text-3xl text-2xl">{{ $t('oferta1') }}</h3>
                <h2 class=" font-grafolita md:text-7xl text-6xl">{{ $t('oferta') }}</h2>
                
                <div class="md:grid grid-cols-5 mt-16 flex justify-center">
                    <h1 class=" col-span-3 md:col-start-2 col-start-1 flex items-start font-sans text-xl md:text-3xl">RON <span class=" self-end text-8xl md:text-9xl font-grafolita md:mt-12 m-4">2800</span>/{{ $t('noapte') }}</h1>
                </div>
                <p class=" font-sans text-base opacity-75 mt-28" v-html="$t('reclama')"></p>
                <a href="#locatie" class="bg-maro-500 hover:bg-maro-500 text-white font-light 2xl:py-3 2xl:px-13 py-3 px-12 rounded my-6 2xl:text-xl text-base w-fit self-start cursor-pointer">
                    {{ $t('contact_button') }}
                </a>
            </div>
        </div>
        <div id="oferta" class=" bg-maro-400 text-white" data-scroll-section>
            <div class=" mx-8 md:mx-40 2xl:mx-80 flex flex-col gap-y-8 pt-24 pb-14 md:items-start items-center">
                <!-- <h3 class=" font-grafolita md:text-3xl text-2xl">{{ $t('oferta1') }}</h3>
                <h2 class=" font-grafolita md:text-7xl text-6xl">{{ $t('oferta') }}</h2> -->
                
                <div class="md:grid grid-cols-5 mt-16 flex justify-center">
                    <h1 class=" col-span-3 md:col-start-2 col-start-1 flex items-start font-sans text-xl md:text-3xl">RON <span class=" self-end text-8xl md:text-9xl font-grafolita md:mt-12 m-4">3500</span>/{{ $t('noapte1') }}</h1>
                </div>
                <p class=" font-sans text-base opacity-75 mt-28" v-html="$t('reclama')"></p>
                <a href="#locatie" class="bg-maro-700 hover:bg-maro-700 text-white font-light 2xl:py-3 2xl:px-13 py-3 px-12 rounded my-6 2xl:text-xl text-base w-fit self-start cursor-pointer">
                    {{ $t('contact_button') }}
                </a>
            </div>
        </div>
        <div id="oferta" class=" bg-maro-700 text-white" data-scroll-section>
            <div class=" mx-8 md:mx-40 2xl:mx-80 flex flex-col gap-y-8 pt-24 pb-14 md:items-start items-center">
                <!-- <h3 class=" font-grafolita md:text-3xl text-2xl">{{ $t('oferta1') }}</h3>
                <h2 class=" font-grafolita md:text-7xl text-6xl">{{ $t('oferta') }}</h2> -->
                
                <div class="md:grid grid-cols-5 mt-16 flex justify-center">
                    <h1 class=" col-span-3 md:col-start-2 col-start-1 flex items-start font-sans text-xl md:text-3xl">RON <span class=" self-end text-8xl md:text-9xl font-grafolita md:mt-12 m-4">5800</span>/{{ $t('noapte2') }}</h1>
                </div>
                <p class=" font-sans text-base opacity-75 mt-28" v-html="$t('reclama')"></p>
                <a href="#locatie" class="bg-maro-500 hover:bg-maro-500 text-white font-light 2xl:py-3 2xl:px-13 py-3 px-12 rounded my-6 2xl:text-xl text-base w-fit self-start cursor-pointer">
                    {{ $t('contact_button') }}
                </a>
            </div>
        </div>
        <div id="locatie" class=" bg-bej-500 text-maro-500" data-scroll-section>
            <div class=" mx-8 md:mx-40 2xl:mx-80 md:grid grid-cols-3 auto-rows-auto py-16 gap-x-4 gap-y-4 flex flex-col items-start">
                <h3 class=" text-3xl font-grafolita col-span-1 row-span-1 col-start-1 row-start-1">{{ $t('ezitati') }}</h3>
                <h2 class=" text-7xl font-grafolita col-span-1 row-span-1 col-start-1 row-start-2">{{ $t('con') }}</h2>

                <span class="flex items-center justify-center gap-2 2xl:text-2xl text-xl font-sans row-span-1 row-start-2 col-span-1">
                   <img src="../assets/images/telefon-maro.svg" alt="Telefon">
                   +40 742 116 999
                </span>
                <span class="flex items-center justify-center gap-2 2xl:text-2xl text-xl font-sans row-span-1 row-start-2 col-span-1">
                   <img src="../assets/images/mail-maro.svg" alt="Email">
                   vilacrisalia@gmail.com
                </span>
                <!--  -->
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1333.8805823885994!2d23.90724485343576!3d45.763561746800306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474c4130144ce0a3%3A0x58447181afbfa4e4!2sVila%20Crisalia!5e0!3m2!1sro!2sro!4v1712646828353!5m2!1sro!2sro" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class=" col-span-3 row-span-1 row-start-3 col-start-1 mt-8 md:block hidden"></iframe>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1333.8805823885994!2d23.90724485343576!3d45.763561746800306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474c4130144ce0a3%3A0x58447181afbfa4e4!2sVila%20Crisalia!5e0!3m2!1sro!2sro!4v1712646828353!5m2!1sro!2sro" width="100%" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class=" col-span-3 row-span-1 row-start-3 col-start-1 mt-8 md:hidden block"></iframe>
            </div>
        </div>
    </div>
    </div>
  </template>
  
  <script>

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EventBus } from '../eventBus';
// import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';




  export default {
    name: 'HomePage',
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Navigation, Pagination, Scrollbar, A11y],
      };
    },
    data() {
        return {
        xPosition: '50%',
        yPosition: '50%',
        images: [
            { src: '../assets/images/heroImg.png', alt: 'Description 1' },
            { src: '../assets/images/heroImg.png', alt: 'Description 2' },
            // ... more image objects
        ],
        isMenuVisible: false,
        videoEnded: false,
        backgroundImages: [
            require('@/assets/images/heroImg.png'),
            require('@/assets/images/p10.png'),
            require('@/assets/images/p12.png'),
            // ...other image paths
        ],
        currentBackgroundIndex: 0,
        currentBackgroundImage: require('@/assets/images/heroImg.png'), // Start with the first image
        isCookieConsentVisible: true,
        };
    },
    
    mounted() {
        window.addEventListener('click', this.handleClickOutside);
        
    },
    unmounted() {
        window.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        toggleMenu(event) {
            this.isMenuVisible = !this.isMenuVisible;
            event.stopPropagation(); // Prevent click from immediately closing the menu
        },
        handleClickOutside(event) {
            // Check if menu is open and the click target is not the menu or its children
            if (this.isMenuVisible && !event.target.closest('.menu-content')) {
            this.isMenuVisible = false;
            }
        },
        onVideoEnded() {
            this.videoEnded = true;
            this.startBackgroundCarousel();
        },
        startBackgroundCarousel() {
            let nextIndex = 0;
            setInterval(() => {
                nextIndex = (nextIndex + 1) % this.backgroundImages.length;
                this.currentBackgroundImage = this.backgroundImages[nextIndex];
            }, 5000); // Change image every 5 seconds
        },
        hideCookieConsent() {
            this.isCookieConsentVisible = false; // This will hide the modal
        // You would also typically set a cookie or local storage flag here
        },
        acceptCookies() {
        // Set a cookie to remember consent
        this.setCookie('cookieConsent', true, 365); // Expires in 1 year
        this.isCookieConsentVisible = false;
        },
        setCookie(name, value, days) {
        const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
        document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
        },
        getCookie(name) {
            const nameEQ = `${name}=`;
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i];
                while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1, cookie.length);
                }
                if (cookie.indexOf(nameEQ) === 0) {
                return cookie.substring(nameEQ.length, cookie.length);
                }
            }
            return null;
        },
        // onSlideChangeTransitionStart() {
        //     // Check the translate3d value here and decide if you need to do something
        //     // For example, if the value is less than -768px, then do something
        //     let translateValue = this.swiper.translate;
        //     if (translateValue > -2000) {
        //         // Your logic to preload or load the next slide
        //         // You might need to directly manipulate the DOM or use Swiper API methods
        //     }
        // }
        changeLanguage(lang) {
            this.$i18n.locale = lang;
        },
        emitScrollToSection(sectionId) {
            EventBus.emit('scroll-to-section', sectionId);
        }
    },
    created() {
        // Check for existing cookie on component creation
        const cookieValue = this.getCookie('cookieConsent');
        if (cookieValue) {
        this.isCookieConsentVisible = false;
        }
    },
  }
  </script>
  
  <style scoped>
  /* Component-specific styles if necessary */
  
  </style>
  