import { createApp } from 'vue'
import './assets/styles/index.css';
import i18n from './plugins/i18n'; // Ensure the path is correct

import App from './App.vue'
import router from './router' // Import the router

import { createMetaManager } from 'vue-meta'; // Ensure this is the next version


createApp(App)
  .use(router) // Use the router
  .use(i18n)
  .use(createMetaManager()) // Use meta manager for handling meta info
  .mount('#app')
