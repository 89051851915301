

<template>
  <div id="app" ref="appContainer">
    <!-- <div ref="scrollContent" class="scroll-content"> -->
      <!-- <div data-scroll-section> -->
        <router-view/>
      <!-- </div> -->
      <footer class="footer bg-maro-700 text-white">
        <div class="mx-8 pb-16 md:mb-0 lg:mx-40 2xl:mx-80 grid md:grid-cols-5 grid-cols-2 md:grid-rows-1 auto-rows-auto gap-x-8 border-b-white border-b-2">
          <a href="/" class="flex items-start">
            <img src="./assets/images/Logo.png" alt="" class="col-span-1 col-start-1 h-4/6 w-auto md:row-start-1 row-start-1 object-contain">
          </a>
          <div class="flex flex-col justify-center gap-4 mt-4 md:row-start-1 row-start-2 col-start-1 col-span-1 md:col-start-2 mb-12 md:mb-0">
            <h4 class="font-grafolita 2xl:text-3xl lg:text-2xl text-2xl">{{ $t('legal.title') }}</h4>
            <a href="/tnc" class="font-sans 2xl:text-lg lg:text-base">{{ $t('legal.terms_and_conditions') }}</a>
            <a href="/cookies" class="font-sans 2xl:text-lg lg:text-base">{{ $t('legal.cookie_policy') }}</a>
            <a href="/regulament" class="font-sans 2xl:text-lg lg:text-base">{{ $t('legal.regulations') }}</a>
          </div>
          <div class="flex flex-col justify-center gap-4 mt-4 md:row-start-1 row-start-2 col-start-2 col-span-1 md:col-start-3 mb-12 md:mb-0">
            <h4 class="font-grafolita 2xl:text-3xl lg:text-2xl text-2xl">{{ $t('inn.title') }}</h4>
            <a href="#facilitati" class="font-sans 2xl:text-lg lg:text-base">{{ $t('inn.facilities') }}</a>
            <a href="#atractii" class="font-sans 2xl:text-lg lg:text-base">{{ $t('inn.tourist_attractions') }}</a>
            <a href="#galerie" class="font-sans 2xl:text-lg lg:text-base">{{ $t('inn.gallery') }}</a>
          </div>
          <div class="flex flex-col justify-center gap-4 mt-4 md:row-start-1 row-start-3 col-start-1 col-span-1 md:col-start-4">
            <h4 class="font-grafolita 2xl:text-3xl lg:text-2xl text-2xl">{{ $t('rental.title') }}</h4>
            <a href="#oferta" class="font-sans 2xl:text-lg lg:text-base">{{ $t('rental.prices_packages') }}</a>
            <a href="#reviews" class="font-sans 2xl:text-lg lg:text-base">{{ $t('rental.testimonials') }}</a>
            <a href="#locatie" class="font-sans 2xl:text-lg lg:text-base">{{ $t('rental.contact') }}</a>
          </div>
          <div class="flex flex-col justify-center gap-4 md:row-start-1 row-start-3 col-start-2 col-span-1 md:col-start-5">
            <a href="https://anpc.ro/ce-este-sal/">
              <img src="./assets/images/anpc.png" alt="anpc_sal">
            </a>
            <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO">
              <img src="./assets/images/anpc_sol.png" alt="anpc sol">
            </a>
          </div>
        </div>
        <div class="mx-8 lg:mx-40 2xl:mx-80 flex justify-between mt-8 md:flex-row flex-col-reverse items-center gap-y-8 pb-8">
          <h3 class="text-lg font-sans font-light md:w-fit w-2/3 text-center">@{{ $t('copyright') }}</h3>
          <div class="flex justify-between md:w-1/4 w-full">
            <a href="https://m.facebook.com/VILACRISALIASIBIEL">
              <img src="./assets/images/facebook.svg" alt="Facebook" class="col-span-1 col-start-1">
            </a>
            <a href="https://www.tiktok.com/@vilacrisalia7?_t=8lO9LfAvtBZ&_r=1">
              <img src="./assets/images/tiktok.svg" alt="TikTok" class="col-span-1 col-start-2">
            </a>
            <a href="https://www.instagram.com/vilacrisalia?igsh=MWl6Y2szcjE0ZmppeQ==">
              <img src="./assets/images/instagram.svg" alt="Instagram" class="col-span-1 col-start-3">
            </a>
            <a href="https://wa.me/0742116999">
              <img src="./assets/images/whatsapp.svg" alt="WhatsApp" class="col-span-1 col-start-4">
            </a>
          </div>
        </div>
      </footer>
    <!-- </div> -->
  </div>
</template>

<script>
  // import Scrollbar from 'smooth-scrollbar';
  // import { EventBus } from './eventBus';
  
  export default {
    name: 'App'
  }
  </script>
  

 <style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
margin-top: 60px;
} */

html, body, #app {
  height: 100%;
  margin: 0;
  /* overflow: hidden; */
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.footer {
  padding: 20px; /* Adjust padding as needed */
  margin-top: auto;
}

.scrollbar-track {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  width: 8px;
  height: 8px;
}

.scrollbar-thumb {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.scrollbar-track-x {
  bottom: 2px;
  left: 2px;
  right: 2px;
  height: 8px;
}

.scrollbar-track-y {
  top: 2px;
  right: 2px;
  bottom: 2px;
  width: 8px;
}

.reviewSlide {
  display: flex !important;
  justify-content: center;
}



</style>
