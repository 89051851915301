import { createI18n } from 'vue-i18n';

// Import your JSON files containing the translations
import en from '../locales/en.json';
import ro from '../locales/ro.json';
import de from '../locales/de.json';

// Set up the messages object
const messages = {
  en: en,
  ro: ro,
  de: de
};

// Create and export the i18n instance
const i18n = createI18n({
  legacy: false, // Use the Composition API
  locale: 'ro', // Default locale
  fallbackLocale: 'en', // Fallback locale
  messages, // Defined messages
});

export default i18n;
