import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomePage.vue'
import RegulamentVila from '../views/RegulamentVila.vue'
import TermeniConditii from '../views/TermeniConditii.vue'
import PoliticaCookies from '../views/PoliticaCookies.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Vila Crisalia'
    }
  },
  {
    path: '/regulament',
    name: 'Regulament',
    component: RegulamentVila,
    meta: {
      title: 'Regulament - Vila Crisalia'
    }
  },
  {
    path: '/tnc',
    name: 'Termeni si Conditii',
    component: TermeniConditii,
    meta: {
      title: 'T&C - Vila Crisalia'
    }
  },
  {
    path: '/cookies',
    name: 'Politica Cookies',
    component: PoliticaCookies,
    meta: {
      title: 'Cookies - Vila Crisalia'
    }
  },
  // Redirection for /galerie to /#galerie
  {
    path: '/galerie',
    redirect: () => {
      window.location.href = '/#galerie';
      return '/'; // Fallback path in case the redirection doesn't work
    }
  },
  // Catch-all route for handling 404 errors
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = 'Vila Crisalia';
  }
  next();
});

export default router
